import React, { Suspense } from "react";
import Header2 from "./../Common/Header2";
import Footer from "./../Common/Footer";
//import HomeSlider from "./../Elements/HomeSlider";
import HomeServiceSlider from "./../Elements/HomeServiceSlider";
//import HomeServiceSliderMobile from "./../Elements/HomeServiceSliderMobile";
//import HomeHouseDesign from "./../Elements/HomeHouseDesign";
//import HomeOneStopShop from "./../Elements/HomeOneStopShop";
import HomeUniqueCommercial from "./../Elements/HomeUniqueCommercial";
import HomeTrendingHouseDesign from "./../Elements/HomeTrendingHouseDesign";
import HomeStatistics from "../Elements/HomeStatistics";
import HomeStatisticsMobile from "./../Elements/HomeStatisticsMobile";
import HomeFaq from "./../Elements/HomeFaq";
import HomeInteriorMobile from "./../Elements/HomeInteriorMobile";
import HomeInteriorDesktop from "./../Elements/HomeInteriorDesktop";
import HomeElevation from "./../Elements/HomeElevation";
import HomeBlog from "./../Elements/HomeBlog";
import HomeWhyNakshewala from "./../Elements/HomeWhyNakshewala";
import HomePartner from "./../Elements/HomePartner";
import HomeDesignReality from "./../Elements/HomeDesignReality";
import HomeTestimonials from "./../Elements/HomeTestimonials";
import HomeCommercialElevation from "./../Elements/HomeCommercialElevation";
import HomeFloor from "./../Elements/HomeFloor";
import HomeRefer from "./../Elements/HomeRefer";
import HomeCta from "./../Elements/HomeCta";
import HomeFormBanner from "./../Elements/HomeFormBanner";
import { Helmet } from "react-helmet";
import "./css/style.css";
import "./css/bootstrap.min.css";
import "./css/fontawesome/css/font-awesome.min.css";
import configData from "./../../config.js"; 
const HomeHouseDesign = React.lazy(() => import("./../Elements/HomeHouseDesign"));
const HomeServiceSliderMobile = React.lazy(() => import("./../Elements/HomeServiceSliderMobile"));
const HomeOneStopShop = React.lazy(() => import("./../Elements/HomeOneStopShop"));
var bnrimg = require("./../../images/banner/NaksheWala-Architecture-Interior-Planning.webp");

class HomeChrome extends React.Component {
  // Constructor
  constructor(props) {
    super(props);

    this.state = {
      items: [],
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  handleScroll = (event) => {
    this.setState({
      scrollY: window.scrollY,
    });
  };

  render() {
    
    let additionalComponents = "";

    if (this.state.scrollY > 10) {
      localStorage.setItem('show', true);
      additionalComponents = (
        <>
          <HomeUniqueCommercial />
          <HomeTrendingHouseDesign
            alignment="separator-left"
            title="Trending House Design"
            bgcolor="bg-white"
          />
          <HomeStatistics />
          <HomeStatisticsMobile />
          <div className="desktop-hidden">
          <HomeInteriorMobile />
          </div>
          <HomeInteriorDesktop />
          <HomeElevation />
          <HomeRefer />
          <HomeFloor />
          <HomeCommercialElevation />
          <HomeTestimonials />
          <HomeDesignReality />
          <HomeCta />
          <HomePartner />
          <HomeBlog />
          <HomeWhyNakshewala />
          <div className="mt-4">
            <HomeFaq />
          </div>
        </>
      );
    }

    return (
      <>
      <Helmet>
        <title>
          House Design | Floor Plan | House Map | Home Plan | Front Elevation |
          Interior Design
        </title>
        <meta
          name="description"
          content="NaksheWala.com has unique and latest Indian house design and floor plan online for your dream home that have designed by top architects. Call us at +91-8010822233 for expert advice."
        />
        <meta
          name="keywords"
          content="House Design, House Plan, Floor Plans, Home Design, Home Plan, 3D front elevation, House Map, Interior Design, Architectural Design, House Design in India, House Plan India, 3D front elevation India, Floor Plans India, Home Design India, Home Plan India, House Map India, Interior Design India, Indian House Design, Indian House Plan, Indian Floor Plans, Indian Home Design, Indian Home Plan, Indian 3D front elevation, Indian House Map, Indian Interior Design"
        />
        <meta property="og:title" content=" House Design | Floor Plan | House Map | Home Plan | Front Elevation |
          Interior Design" />
        <meta property="og:description" content="NaksheWala.com has unique and latest Indian house design and floor plan online for your dream home that have designed by top architects. Call us at +91-8010822233 for expert advice." />
        <meta property="og:url" content={configData.WEB_URL} />
        <link rel="canonical" href={configData.WEB_URL} />
        <meta property="og:site_name" content={configData.SITE_NAME} />
        <meta property="og:image" content={`${configData.ADMIN_URL}/assets/images/banner/hdr_bnr618a62aea3dd6nakshewala_banner_front_page_design.jpg`} />
      </Helmet>
        <Header2 />
        <div className="page-content">
          <HomeFormBanner
            title=""
            pagename="Home"
            description=""
            title1=""
            bgimage={bnrimg}
          />
          {/* <HomeSlider /> */}
          <HomeServiceSlider />
          <Suspense fallback={<div>Loading...</div>}>
          <HomeServiceSliderMobile />
          <HomeHouseDesign />
          <HomeOneStopShop />
          </Suspense>
            {(() => {
                if (localStorage.getItem('show')) {
                  return (
                      <>
                      <Suspense fallback={<div>Loading...</div>}>
                      <HomeUniqueCommercial />
                      <HomeTrendingHouseDesign
                        alignment="separator-left"
                        title="Trending House Design"
                        bgcolor="bg-white"
                      />
                       
                      <HomeStatistics />
                      <HomeStatisticsMobile />
                      <div className="desktop-hidden">
                      <HomeInteriorMobile />
                      </div>
                      <HomeInteriorDesktop />
                      
                      <HomeElevation />
                      <HomeRefer />
                      <HomeFloor />
                      <HomeCommercialElevation />
                      <HomeTestimonials />
                      <HomeDesignReality />
                      <HomeCta />
                      <HomePartner />
                      <HomeBlog />
                      <HomeWhyNakshewala />
                      <div className="mt-4">
                        <HomeFaq />
                      </div>
                      </Suspense>
                    </>
                  );
                } else {
                  return (
                   <> {additionalComponents}</>
                  );
                }
              })()}
          
          <Footer />
        </div>
      </>
    );
  }
}

export default HomeChrome;
